.pdf_btn {
  background: #253744 !important;
  width: 13rem !important;
  text-align: center !important;
  color: #fff !important;
  font-size: 1rem !important;
  padding: 1rem !important;
  border-radius: 4% !important;
  box-shadow: 5px 5px 7px #12202b;
  // animation: shakeme 3s infinite;
}

.pdf_btn:hover {
  background-color: #687d8c !important;
  transform: scale(1.1) !important;
  transition: all 0.3s;
}

.active {
  background-color: #bbbdce !important;
}

.close {
  position: absolute;
  background-color: #f8355a;
  right: 0;
  margin-top: -0.7rem;
  margin-right: -0.4rem;
  width: 1.7rem;
  height: 25px;
  border-radius: 50%;
  color: white;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
}
 .file_wrapper{
  position: relative;
 }


// @keyframes shakeme {
//     0% {
//       transform: scale(1);
//     }
//     5% {
//       transform: scale(0.7);
//     }
//     10% {
//       transform: scale(1.5);
//     }
//     15% {
//       transform: scale(1.1);
//     }
//     20% {
//       transform: scale(1.4);
//     }
//     30% {
//       transform: scale(1.1);
//     }
//   }
